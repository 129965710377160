import { MODAL_STATE } from "components/Navbar/constants"

import { useState } from "react"

const useNavbar = () => {
	const [modalState, setModalState] = useState({ state: MODAL_STATE.HIDDEN })

	const closeModal = () => {
		setModalState({
			state: MODAL_STATE.HIDDEN,
		})
	}
	const openModal = () => {
		setModalState({
			state: MODAL_STATE.VISIBLE,
		})
	}

	return {
		isModalOpen: modalState.state === "VISIBLE",
		closeModal,
		openModal,
	}
}
export default useNavbar
