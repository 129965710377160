/**
 * Don't change this archive without talking with a BACK-END,
 * because these constants come from the API.
 */
export const PERMISSIONS = {
	POWER_USER: {
		GRANTED: "power user granted",
		REVOKED: "power user revoked",
	},
	LEAD: {
		VIEW: "view * leads",
		VIEW_ALL: "view all leads",
		VIEW_OWN: "view own leads",
		VIEW_TEAM: "view team leads",

		CREATE_GRANTED: "create leads granted",
		CREATE_REVOKED: "create leads revoked",

		TRANSFER_GRANTED: "transfer leads granted",
		TRANSFER_REVOKED: "transfer leads revoked",

		TAKE_GRANTED: "take leads granted",
		TAKE_REVOKED: "take leads revoked",

		EDIT: "edit * leads",
		EDIT_ALL: "edit all leads",
		EDIT_OWN: "edit own leads",
		EDIT_TEAM: "edit team leads",

		DELETE: "delete * leads",
		DELETE_ALL: "delete any leads",
		DELETE_OWN: "delete own leads",
		DELETE_TEAM: "delete team leads",

		IMPORT_GRANTED: "import leads granted",
		IMPORT_REVOKED: "import leads revoked",

		EXPORT_GRANTED: "export leads granted",
		EXPORT_REVOKED: "export leads revoked",
	},
	PROPOSALS: {
		VIEW: "view * proposals",
		VIEW_ALL: "view all proposals",
		VIEW_OWN: "view own proposals",
		VIEW_TEAM: "view team proposals",

		CREATE_GRANTED: "create proposals granted",
		CREATE_REVOKED: "create proposals revoked",
	},
	WALLET: {
		VIEW: "view * wallets",
		VIEW_ALL: "view all wallets",
		VIEW_OWN: "view own wallets",
		VIEW_TEAM: "view team wallets",
	},
	IMPORTS: {
		VIEW: "view * imports",
		VIEW_ALL: "view all imports",
		VIEW_OWN: "view own imports",
		VIEW_TEAM: "view team imports",
	},
	REPORTS: {
		VIEW: "view * reports",
		VIEW_ALL: "view all reports",
		VIEW_OWN: "view own reports",
		VIEW_TEAM: "view team reports",
	},
	ACTIVITIES: {
		VIEW: "view * activities",
		VIEW_ALL: "view all activities",
		VIEW_OWN: "view own activities",
		VIEW_TEAM: "view team activities",

		CREATE_GRANTED: "create activities granted",
		CREATE_REVOKED: "create activities revoked",
	},
	PRODUCT: {
		VIEW: "view * products",
		VIEW_ALL: "view all products",
		VIEW_TEAM: "view team products",

		CREATE_GRANTED: "create products granted",
		CREATE_REVOKED: "create products revoked",

		EDIT: "edit * products",
		EDIT_ALL: "edit all products",
		EDIT_OWN: "edit own products",
		EDIT_TEAM: "edit team products",

		DELETE: "delete * products",
		DELETE_ALL: "delete any products",
	},
	USER: {
		VIEW: "view * users",
		VIEW_ALL: "view all users",
		VIEW_TEAM: "view team users",

		CREATE_GRANTED: "create users granted",
		CREATE_REVOKED: "create users revoked",

		EDIT: "edit * users",
		EDIT_ALL: "edit all users",
		EDIT_OWN: "edit own users",
		EDIT_TEAM: "edit team users",

		DELETE: "delete * users",
		DELETE_ALL: "delete any users",
		DELETE_TEAM: "delete team users",
	},
	TEAM: {
		VIEW: "view * teams",
		VIEW_ALL: "view all teams",

		CREATE_GRANTED: "create teams granted",
		CREATE_REVOKED: "create teams revoked",

		EDIT: "edit * teams",
		EDIT_ALL: "edit all teams",

		DELETE: "delete * teams",
		DELETE_ALL: "delete any teams",
	},
	KANBAN: {
		USE_GRANTED: "use kanban granted",
		USE_REVOKED: "use kanban revoked",
	},
	WHATSAPP: {
		USE_GRANTED: "use whatsapp granted",
		USE_REVOKED: "use whatsapp revoked",
	},
	OFFER: {
		USE_GRANTED: "use offers granted",
		USE_REVOKED: "use offers revoked",
	},
	MANAGE: {
		USER_PERMISSIONS_GRANTED: "manager * user permissions granted",
		USER_PERMISSIONS_REVOKED: "manager * user permissions revoked",

		ALL_USER_PERMISSIONS_GRANTED: "manager all user permissions granted",
		ALL_USER_PERMISSIONS_REVOKED: "manager all user permissions revoked",

		TEAM_USER_PERMISSIONS_GRANTED: "manager team user permissions granted",
		TEAM_USER_PERMISSIONS_REVOKED: "manager team user permissions revoked",

		USER_STATUS_GRANTED: "manager * user status granted",
		USER_STATUS_REVOKED: "manager * user status revoked",

		ALL_USER_STATUS_GRANTED: "manager all user status granted",
		ALL_USER_STATUS_REVOKED: "manager all user status revoked",

		TEAM_USER_STATUS_GRANTED: "manager team user status granted",
		TEAM_USER_STATUS_REVOKED: "manager team user status revoked",
	},
}
