import { Icon } from "@redsales/ds"
import { _array } from "@redsales/ds/utils"

import If from "components/If"
import NavbarLink from "../Link"
import NavbarSections from "../Sections"

import "./Page.scss"

const NavbarPage = ({
	sections,
	path = "",
	label = "",
	highlightByLinkStart,
}) => (
	<li className="navbar-page" key={label}>
		<NavbarLink
			to={path}
			className={"navbar-page__link"}
			highlightByLinkStart={highlightByLinkStart}
			activeClassName="navbar-page__link--highlighted"
		>
			{label}

			<If condition={_array.hasAtLeast(1, sections)}>
				<Icon icon="chevron-down" />
			</If>
		</NavbarLink>
		<NavbarSections sections={sections} key={label} />
	</li>
)

export default NavbarPage
