import { isLinkActive } from './utils'
import { Link as GatsbyLink } from 'gatsby-link'

const NavbarLink = ({
	children,
	className = '',
	highlightByLinkStart,
	activeClassName = 'active',
	...props
}) => (
	<GatsbyLink
		{...props}
		getProps={isLinkActive({
			className,
			activeClassName,
			highlightByLinkStart,
		})}
	>
		{children}
	</GatsbyLink>
)

export default NavbarLink
