import LoggedIn from './LoggedIn'
import LoggedOut from './LoggedOut'
import { _browser } from '@redsales/ds/utils'
import If from 'components/If'
import Message from 'components/Message'

const Layout = ({ children, props }) => {
	return (
		<If condition={_browser.isClient()}>
			<Message />
			<If
				condition={props?.pageContext?.layout === 'logged-out'}
				renderIf={<LoggedOut>{children}</LoggedOut>}
				renderElse={<LoggedIn>{children}</LoggedIn>}
			/>
		</If>
	)
}

export default Layout
