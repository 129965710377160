import { Icon, Mobile } from "@redsales/ds"
import { _array } from "@redsales/ds/utils"

import If from "components/If"
import NavbarLink from "../Link"
import classnames from "classnames"
import { ContainerByPermissions } from "../ContainerByPermissions"

import "./Sections.scss"

const NavbarSections = ({
	actionPositionFromRight,
	sections = [],
	key = "",
	isModalOpen,
	closeModal,
}) => {
	return (
		<If condition={_array.hasAtLeast(1, sections)}>
			<If condition={isModalOpen}>
				<Mobile>
					<Icon
						onClick={closeModal}
						className="navbar-sections--close"
						name="close"
					/>
				</Mobile>
			</If>
			<ul
				className={classnames("navbar-sections", {
					"navbar-sections--from-right": actionPositionFromRight,
					"navbar-sections__opened": isModalOpen,
				})}
			>
				{sections?.map((section, index) => (
					<ContainerByPermissions
						permissions={section?.permissions}
						index={index}
						key={`${key}container_nav_sections#${index}`}
					>
						<li
							className={classnames("navbar-sections__item", {
								"navbar-sections__item--readonly": section?.readOnly,
							})}
							key={`${key}nav_sections#${index}`}
						>
							<If
								condition={!!section?.path}
								renderIf={
									<If
										condition={!!section?.sections}
										renderIf={
											<details>
												<summary className=" navbar-sections__accordion">
													{section?.label}
													<Icon icon="chevron-down" />
												</summary>

												{section?.sections?.map((page, i) => (
													<NavbarLink
														to={page?.path}
														activeClassName="navbar-sections__item--highlighted"
														highlightByLinkStart={section?.highlightByLinkStart}
														onClick={closeModal}
													>
														<li className=" navbar-sections__item--accordion">
															{page?.label}
														</li>
													</NavbarLink>
												))}
											</details>
										}
										renderElse={
											<NavbarLink
												to={section?.path}
												activeClassName="navbar-sections__item--highlighted"
												highlightByLinkStart={section?.highlightByLinkStart}
												onClick={closeModal}
											>
												{section?.label}
											</NavbarLink>
										}
									/>
								}
								renderElse={
									<If
										condition={section?.onClick}
										renderIf={
											<button
												className="navbar-sections__item--link"
												onClick={section?.onClick}
											>
												{section?.label}
											</button>
										}
										renderElse={<>{section?.label}</>}
									/>
								}
							/>
						</li>
					</ContainerByPermissions>
				))}
			</ul>
		</If>
	)
}

export default NavbarSections
