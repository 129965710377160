import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import If from "components/If"

const Img = ({ file, ...props }) => {
	const getStaticImageByFilename = ({ images, filename }) =>
		images?.edges?.find((image) => image?.node?.name === filename)

	return (
		<StaticQuery
			query={graphql`
				query {
					images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
						edges {
							node {
								relativePath
								name
							}
						}
					}
				}
			`}
			render={({ images }) => {
				const image = getStaticImageByFilename({ images, filename: file })

				return (
					<If
						condition={image}
						renderIf={
							<Image fluid={image?.node?.childImageSharp?.fluid} {...props} />
						}
					/>
				)
			}}
		/>
	)
}

export default Img
