import { PERMISSIONS } from "constants/permissions"
import { navigate } from "gatsby"
import { useEffect, useCallback, useState } from "react"

import storage from "utils/storage"

const useCan = ({ and, action, url, not: cannot }) => {
	const permissions = JSON.parse(storage.session.getItem("permissions"))
	const [allowed, setAllowed] = useState(null)

	const props = {
		action: [action].flat(),
	}

	const getWildcardIndexAndPosition = (action = "") => {
		const middleIndex = action.indexOf(" * ")
		if (middleIndex > -1) return { position: "middle", index: middleIndex }

		const startIndex = action.indexOf("* ")
		if (startIndex > -1) return { position: "start", index: startIndex }

		const endIndex = action.indexOf(" *")
		if (endIndex > -1) return { position: "end", index: endIndex }

		return { position: "none", index: -1 }
	}

	const validateActionByWildcard = ({ action, permission }) => {
		const wildcard = getWildcardIndexAndPosition(action)

		if (wildcard.position === "start") {
			//"*( is example)" -> the part inside the parentheses is the destination string
			const endSubstr = action.substring(wildcard.index + 1)

			if (permission.endsWith(endSubstr)) return true
		}

		if (wildcard.position === "end") {
			//"(text is )*" -> the part inside the parentheses is the destination string
			const startSubstr = action.substring(0, wildcard.index)

			if (permission.startsWith(startSubstr)) return true
		}

		if (wildcard.position === "middle") {
			//"(text) * example" -> the part inside the parentheses is the destination string
			const startSubstr = action.substring(0, wildcard.index)
			//"text *( example)" -> the part inside the parentheses is the destination string
			const endSubstr = action.substring(wildcard.index + 2)

			if (permission.startsWith(startSubstr) && permission.endsWith(endSubstr))
				return true
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const toFiltredRulesByProps = (permission) => {
		for (let i = 0, len = props.action.length; i < len; i++) {
			const currentAction = props.action[i]

			if (permission === currentAction) {
				return true
			}

			const hasValidWildCardCase = validateActionByWildcard({
				action: currentAction,
				permission,
			})

			if (hasValidWildCardCase) return true
		}
		return false
	}

	const getIfPowerUserIsGranted = (permission) =>
		permission === PERMISSIONS.POWER_USER.GRANTED

	const handlePermissions = useCallback(() => {
		const filtredPermissions = permissions?.filter(toFiltredRulesByProps)

		const isPowerUser = permissions?.find(getIfPowerUserIsGranted)
		const sameLengthArray = filtredPermissions?.length === props.action.length
		const allConditionsAreTruly = and && sameLengthArray && !cannot
		const justAnConditionsIsTruly =
			!and && filtredPermissions?.length && !cannot

		if (isPowerUser) {
			return setAllowed(true)
		}

		if (allConditionsAreTruly) {
			return setAllowed(true)
		}

		if (justAnConditionsIsTruly) {
			return setAllowed(true)
		}

		if (url) {
			return navigate(url)
		}
	}, [
		permissions,
		toFiltredRulesByProps,
		props.action.length,
		and,
		cannot,
		url,
	])

	useEffect(() => {
		handlePermissions()
	}, [handlePermissions])

	return {
		allowed,
	}
}

export default useCan
