import { Fragment } from "react"

import If from "components/If"
import Navbar from "components/Navbar"
import Loader from "components/Loader"
import Redirect from "components/Redirect"

import useLoggedIn from "./hooks"

import "./LoggedIn.scss"

const LoggedIn = ({ children }) => {
	const { isLoggedIn } = useLoggedIn()

	return (
		<Fragment>
			<div className="ds-loader">
				<Loader />
			</div>
			<If
				condition={isLoggedIn}
				renderIf={
					<Fragment>
						<Navbar className="app__navbar" />
						<main className="app__main">{children}</main>
					</Fragment>
				}
				renderElse={<Redirect to="/autenticacao/login" />}
			/>
		</Fragment>
	)
}

export default LoggedIn
