import Layout from "layout"

import { Helmet } from "react-helmet"

function wrapElement({ element, ...props }) {
	return (
		<div className="app">
			<Helmet>
				<title>Data Bridge</title>
				<link rel="icon" type="image/x-icon" href="favicon-32x32.png" />
			</Helmet>

			<Layout {...props}>{element}</Layout>
		</div>
	)
}

export default wrapElement
