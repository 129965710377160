import { _browser } from "@redsales/ds/utils"
import storage from "utils/storage"
import jwt_decode from "jwt-decode"

const decoding = (payload) => {
	return jwt_decode(payload)
}

const getDataFromToken = (token) => {
	if (_browser.isClient() && token) {
		const decodedPayload = decoding(token) || "{}"
		return decodedPayload
	} else {
		return {}
	}
}

const getUser = () => {
	if (_browser.isClient()) {
		const user = JSON.parse(storage.session.getItem("userInfo"))

		return user
	}

	return {}
}

const getToken = () => {
	if (_browser.isClient()) {
		const appToken = JSON.parse(storage.session.getItem("appToken"))
		const tokenInfo = getDataFromToken(appToken?.token)

		return tokenInfo
	}

	return {}
}

const parseToken = ({ token, valid_time }) => ({
	token,
	expires: (valid_time - 60) * 1000,
})

export default { decoding, getDataFromToken, getUser, getToken, parseToken }
