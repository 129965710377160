import { useEffect } from "react"
import { _browser } from "@redsales/ds/utils"

import Img from "components/Image"
import storage from "utils/storage"
import { LOGO } from "gatsby-env-variables"

import "./LoggedOut.scss"

const LoggedOut = ({ children }) => {
	useEffect(() => {
		if (_browser.isClient()) {
			storage.session.clear()
		}
	}, [])

	return (
		<section className="logged-out__wrapper">
			<div className="logged-out__form-container">
				<img src={`/assets/images/${LOGO.ICON}.svg`} alt="Logo Data Bridge" />
				<div className="logged-out__children">{children}</div>
			</div>

			<div className="logged-out__image-container">
				<div className="logged-out__image-background"></div>
				<Img
					file="login"
					alt="Home da Data Bridge"
					className="logged-out__image"
				/>
			</div>
		</section>
	)
}

export default LoggedOut
