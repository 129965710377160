exports.components = {
  "component---src-pages-access-management-users-index-js": () => import("./../../../src/pages/AccessManagement/Users/index.js" /* webpackChunkName: "component---src-pages-access-management-users-index-js" */),
  "component---src-pages-auth-change-password-index-js": () => import("./../../../src/pages/Auth/ChangePassword/index.js" /* webpackChunkName: "component---src-pages-auth-change-password-index-js" */),
  "component---src-pages-auth-first-access-index-js": () => import("./../../../src/pages/Auth/FirstAccess/index.js" /* webpackChunkName: "component---src-pages-auth-first-access-index-js" */),
  "component---src-pages-auth-forgot-password-index-js": () => import("./../../../src/pages/Auth/ForgotPassword/index.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-index-js" */),
  "component---src-pages-auth-login-index-js": () => import("./../../../src/pages/Auth/Login/index.js" /* webpackChunkName: "component---src-pages-auth-login-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-upload-index-js": () => import("./../../../src/pages/Upload/index.js" /* webpackChunkName: "component---src-pages-upload-index-js" */)
}

