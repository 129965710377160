import Can from 'components/Can'
import { Fragment } from 'react'

export const ContainerByPermissions = ({
	permissions = null,
	children,
	path = '',
	index = Math.random(),
}) => {
	if (!!permissions)
		return (
			<Can action={permissions} key={`NavContainer${path}${index}`}>
				{children}
			</Can>
		)

	return <Fragment key={`NavContainer${path}${index}`}>{children}</Fragment>
}
