export const isLinkActive =
	({ className, activeClassName, highlightByLinkStart }) =>
	({ isCurrent, location }) => {
		const isCurrentPageOrStartsWithHighlight =
			isCurrent ||
			(!!highlightByLinkStart &&
				String(location?.pathname).startsWith(highlightByLinkStart))

		if (isCurrentPageOrStartsWithHighlight)
			return {
				className: `${className} ${activeClassName}`,
			}

		return { className }
	}
