import "@redsales/ds/dist/style.css"
import "./src/styles/main.scss"
import Root from "./src/Root"

export const wrapPageElement = ({ element, ...rest }) => {
	return <Root element={element} {...rest} />
}

Promise.resolve(
	import(
		/* webpackChunkName: "thirdPartyScripts" */ "./src/utils/bootstrap/newrelic"
	)
)
