import { ENV } from "gatsby-env-variables"
import { _storage } from "@redsales/ds/utils"

const key = `storage-pivo-${ENV}`
const storage = _storage({ prefix: key })

const local = storage.local
const session = storage.session

export default {
	session,
	local,
}

/* Methods
  getItem(item)
  setItem(name, value)
  getLength()
  removeItem(name),
  clearWithPrefix(),
  clear(),
*/
