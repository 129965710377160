import { Desktop, Icon } from "@redsales/ds"
import { LOGO } from "gatsby-env-variables"

import NavbarPage from "./components/Page"
import { ACTIONS, PAGES } from "./constants"
import NavbarAction from "./components/Action"
import { ContainerByPermissions } from "./components/ContainerByPermissions"

import "./Navbar.scss"

const Navbar = () => {
	return (
		<nav className="navbar">
			<div className="navbar__logo">
				<img src={`/assets/images/${LOGO.ICON}.svg`} alt="Logo Data Bridge" />
			</div>

			<section className="navbar__items">
				<ul className="navbar__pages">
					<Desktop>
						{PAGES.map((page, index) => (
							<ContainerByPermissions
								key={`NavbarPage${page?.path}${index}`}
								permissions={page?.permissions}
								path={page?.path}
								index={index}
							>
								<NavbarPage {...page} key={`NavbarPage${page?.path}${index}`} />
							</ContainerByPermissions>
						))}
					</Desktop>
				</ul>
				<ul className="navbar__actions">
					{ACTIONS().map((props) => {
						return <NavbarAction key={props.key} {...props} />
					})}
				</ul>
			</section>
		</nav>
	)
}

export default Navbar
