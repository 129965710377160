import If from "components/If"
import useCan from "hooks/useCan"

const Can = ({ children, not = false, and = false, otherwise, ...props }) => {
	const action = props.action || props.the || props.do || props.see || props.on

	const url = otherwise?.goTo
	const { allowed: render } = useCan({ action, url, and, not })

	return (
		<If condition={render}>
			<>{children}</>
		</If>
	)
}

export default Can
