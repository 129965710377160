import { Icon, Desktop, Mobile } from '@redsales/ds'
import { useMediaQuery } from '@redsales/ds/hooks'
import { PERMISSIONS } from 'constants/permissions'
import { navigate } from 'gatsby'
import auth from 'utils/auth'
import storage from 'utils/storage'

const getUserData = () => {
	return auth.getUser()
}
const logOut = () => {
	storage.session.clear()
	navigate(ROUTES.login.path)
}

export const PAGES = [
	/**
	 * REMOVE THIS COMMENT WHEN THIS PAGE BE ENABLED
	 */
	// { ...ROUTES.home },
	{
		...ROUTES.upload,
		permissions: [
			PERMISSIONS.IMPORTS.VIEW,
			PERMISSIONS.LEAD.IMPORT_GRANTED,
			PERMISSIONS.LEAD.EXPORT_GRANTED,
		],
	},
	/**
	 * REMOVE THIS COMMENT WHEN THIS PAGE BE ENABLED
	 */
	// {
	// 	...ROUTES.proposals,
	// 	permissions: [
	// 		PERMISSIONS.PROPOSALS.VIEW,
	// 		PERMISSIONS.PROPOSALS.CREATE_GRANTED,
	// 	],
	// },
	{
		...ROUTES.users,
		label: 'Gestão de acessos',
		permissions: [
			PERMISSIONS.USER.VIEW,
			PERMISSIONS.USER.EDIT,
			PERMISSIONS.USER.DELETE,
			PERMISSIONS.USER.CREATE_GRANTED,
			PERMISSIONS.TEAM.CREATE_GRANTED,
			PERMISSIONS.TEAM.DELETE,
			PERMISSIONS.TEAM.EDIT,
			PERMISSIONS.TEAM.VIEW,
		],
	},
]

export const ACTIONS = (pages = []) => {
	const isMobile = useMediaQuery('screen and (max-width: 767px)')
	const PAGE_MOBILE = isMobile ? PAGES : []

	const userData = getUserData()

	return [
		{
			key: 'user-data',
			label: (
				<>
					<Desktop>
						<Icon icon="user" />
					</Desktop>
					<Mobile>
						<Icon icon="menu" />
					</Mobile>
				</>
			),
			actionPositionFromRight: true,
			sections: [
				{
					label: <b>{userData?.name}</b>,
					readOnly: true,
				},

				...PAGE_MOBILE,

				{
					label: (
						<>
							<Icon icon="power" />
							Logout
						</>
					),
					onClick: logOut,
				},
			],
		},
	]
}

export const MODAL_STATE = {
	HIDDEN: 'HIDDEN',
	VISIBLE: 'VISIBLE',
}
