import NavbarSections from "../Sections"
import useNavbar from "components/Navbar/hooks"

import "./Action.scss"
import { _object } from "@redsales/ds/utils"

const NavbarAction = ({
	label,
	sections,
	actionPositionFromRight,
	...props
}) => {
	const { isModalOpen, openModal, closeModal } = useNavbar()
	return (
		<li className="navbar-action" key={_object.generateUuid()}>
			<button className="navbar-action__icon" onClick={openModal}>
				{label}
			</button>
			<NavbarSections
				sections={sections}
				key={props?.key || ""}
				actionPositionFromRight={!!actionPositionFromRight}
				closeModal={closeModal}
				isModalOpen={isModalOpen}
			/>
		</li>
	)
}

export default NavbarAction
