import { useEffect } from "react"
import auth from "utils/auth"
import storage from "utils/storage"

const useLoggedIn = () => {
	const user = auth.getToken()
	const exp = user?.exp
	const isValidToken = exp * 1000 > Date.now()
	const isLoggedIn = isValidToken
	const permissions = JSON.parse(storage.session.getItem("permissions"))

	useEffect(() => {
		console.group("PERMISSIONS", permissions)
		console.groupEnd()
	}, [permissions])

	return {
		isLoggedIn: isLoggedIn,
	}
}

export default useLoggedIn
